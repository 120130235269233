import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Hamburger from "./Hamburger";
import { NotificationBell } from "./svgindex";

const TopBar = ({ img, isMobile, logoUrl, onLogout, toggleSidebar, ulb, userDetails, notificationCount, notificationCountLoaded, cityOfCitizenShownBesideLogo, onNotificationIconClick, hideNotificationIconOnSomeUrlsWhenNotLoggedIn, sidebarOpen, sidebarToggle }) => {

  const [userData, setUserData] = useState(null);
  const userInfo = Digit.UserService.getUser()?.info || {};
  const getUserInfo = async () => {
    const tenant = Digit.ULBService.getCurrentTenantId();
    const uuid = userInfo?.uuid;
    if (uuid) {
      try {
        const usersResponse = await Digit.UserService.userSearch(tenant, { uuid: [uuid] }, {});
        setUserData(usersResponse?.user?.[0]);
      } catch (error) {
        return error;
      }
    }
  };
  const designationName = userData?.roles?.filter(item => item.code !== "CITIZEN").map(item => item.name).join(',') || "Citizen";
  useEffect(() => {
    getUserInfo();
  }, []);
  return (
    <div className="navbar">
      <div className="center-container">
        {isMobile && <Hamburger handleClick={() => sidebarToggle(sidebarOpen)} />}
        <img
          className="city"
          id="topbar-logo" 
          src={logoUrl}
          alt="Himachal"
        />
        {/* <h3>{cityOfCitizenShownBesideLogo}</h3> */}
        <div className="RightMostTopBarOptions">
        <div>
              <div className="row"><span style={{ fontSize: 18 ,textAlign:"center"}}><h3 className="mx-2 fw-bold">{userData?.name ? userData?.name : userInfo?.name}</h3></span></div>
              <div className="row"><p className="mx-2">{designationName}</p></div>
            </div>
          {/* {!hideNotificationIconOnSomeUrlsWhenNotLoggedIn ? <div className="EventNotificationWrapper" onClick={onNotificationIconClick}>
            { notificationCountLoaded && notificationCount ? <span><p>{notificationCount}</p></span> : null }
            <NotificationBell />
          </div> : null} */}
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = {
  img: PropTypes.string,
};

TopBar.defaultProps = {
  img: undefined,
};

export default TopBar;
