const Urls = {
  MDMS: `/egov-mdms-service/v1/_search`,
  opensearchAPI: `/bpa-services/v1/bpa/_plainsearch`,
  WorkFlow: `/egov-workflow-v2/egov-wf/businessservice/_search`,
  WorkFlowProcessSearch: `/egov-workflow-v2/egov-wf/process/_search`,
  localization: `/localization/messages/v1/_search`,
  location: {
    localities: `/egov-location/location/v11/boundarys/_search?hierarchyTypeCode=ADMIN&boundaryType=Locality`,
    revenue_localities: `/egov-location/location/v11/boundarys/_search?hierarchyTypeCode=REVENUE&boundaryType=Locality`,
    business_services: `/bpa-services/v1/bpa/business-services`,
  },

  pgr_search: `/pgr-services/v2/request/_search`,
  pgr_update: `/pgr-services/v2/request/_update`,
  filter_data: `https://run.mocky.io/v3/597a50a0-90e5-4a45-b82e-8a2186b760bd`,
  FileStore: "/filestore/v1/files",

  FileFetch: "/filestore/v1/files/url",
  PGR_Create: `/pgr-services/v2/request/_create`,
  pgr_count: `/pgr-services/v2/request/_count`,

  OTP_Send: "/user-otp/v1/_send",
  Authenticate: "/user/oauth/token",
  RegisterUser: "/user/citizen/_create",
  ChangePassword: "/user/password/nologin/_update",
  ChangePassword1: "/user/password/_update", 
  UserProfileUpdate: "/user/profile/_update",
  EmployeeSearch: "/egov-hrms/employees/_search",

  InboxSearch: "/inbox/v1/_search",
  InboxInspectionSearch: "/inbox/v1/inspection/_search",
  InboxSearchApplication:"/bpa-services/v1/bpa/_dashboard/_count",
  SearchApplication:"/bpa-services/v1/bpa/_dashboard/_applicationDetail",
  tlSearchApplication: "/tl-services/v1/_dashboard/_applicationDetail",
  InboxSearchApplicationProfessional:"/tl-services/v1/_dashboard/_count",

  UserSearch: "/user/_search",
  UserLogout : "/user/_logout",

  Shortener: "/egov-url-shortening/shortener",

  fsm: {
    search: "/fsm/v1/_search",
    create: "/fsm/v1/_create",
    update: "/fsm/v1/_update",
    vendorSearch: "/vendor/v1/_search",
    vehicleSearch: "/vehicle/v1/_search",
    audit: "/fsm/v1/_audit",
    vehicleTripSearch: "/vehicle/trip/v1/_search",
    billingSlabSearch: "/fsm-calculator/v1/billingSlab/_search",
    vehilceUpdate: "/vehicle/trip/v1/_update",
    vehicleTripCreate: "/vehicle/trip/v1/_create",
  },

  form11: {
    create: '/bpa-services/v1/bpa/_createForm',
    auth: '/user/oauth/token'
  },
  form12: {
    create: '/bpa-services/v1/bpa/_createForm',
    auth: '/user/oauth/token'
  },

  payment: {
    fetch_bill: "/billing-service/bill/v2/_fetchbill",
    demandSearch: "/billing-service/demand/_search",
    create_reciept: "/collection-services/payments/_create",
    print_reciept: "/collection-services/payments",
    generate_pdf: "/pdf-service/v1/_create",
    create_citizen_reciept: "/pg-service/transaction/v1/_create",
    update_citizen_reciept: "/pg-service/transaction/v1/_update",
    search_bill: "/billing-service/bill/v2/_search",
    reciept_search: "/collection-services/payments/:buisnessService/_search",
    obps_Reciept_Search: "/collection-services/payments/_search",
  },

  pt: {
    fectch_property: "/property-services/property/_search",
    fetch_payment_details: "/billing-service/bill/v2/_fetchbill",
    create: "/property-services/property/_create",
    search: "/property-services/property/_search",
    update: "/property-services/property/_update",
    pt_calculation_estimate: "/pt-calculator-v2/propertytax/v2/_estimate",
    assessment_create: "/property-services/assessment/_create",
    assessment_search: "/property-services/assessment/_search",
    payment_search: "/collection-services/payments/PT/_search",
    pt_calculate_mutation: "/pt-calculator-v2/propertytax/mutation/_calculate"
  },

  dss: {
    dashboardConfig: "/dashboard-analytics/dashboard/getDashboardConfig",
    getCharts: "/dashboard-analytics/dashboard/getChartV2",
  },

  mcollect: {
    search: "/echallan-services/eChallan/v1/_search",
    create: "/echallan-services/eChallan/v1/_create?",
    fetch_bill: "/billing-service/bill/v2/_fetchbill?",
    search_bill: "/egov-searcher/bill-genie/mcollectbills/_get",
    search_bill_pt: "/egov-searcher/bill-genie/billswithaddranduser/_get",
    update: "/echallan-services/eChallan/v1/_update",
    download_pdf: "/egov-pdf/download/UC/mcollect-challan",
    receipt_download: "/egov-pdf/download/PAYMENT/consolidatedreceipt",
    bill_download: "/egov-pdf/download/bills/consolidatedbill",
    count: "/echallan-services/eChallan/v1/_count",
  },
  hrms: {
    search: "/egov-hrms/employees/_search",
    count: "/egov-hrms/employees/_count",
    create: "/egov-hrms/employees/_create",
    update: "/egov-hrms/employees/_update",
   UserJobdelegationSearch: "/user/users/_jobdelegation",
   EidtJobdelegationSearch: "/user/users/_jobdelegation/_search",
   UserJobdelegationEmployeeSearch: "/user/users/_jobdelegation/_employeeSearch",
  },
  tl: {
    create: "/tl-services/v1/_create",
    search: "/tl-services/v1/_search",
    fetch_payment_details: "/billing-service/bill/v2/_fetchbill",
    download_pdf: "/egov-pdf/download/TL/",
    update: "/tl-services/v1/_update",
    billingslab: "/tl-calculator/billingslab/_search",
    designOfApproval:"/tl-services/_Certificates",
  },
  receipts: {
    receipt_download: "/egov-pdf/download/PAYMENT/consolidatedreceipt",
    payments: "/collection-services/payments",
    count: "/egov-hrms/employees/_count",
  },
  obps: {
    compliance_create :"/bpa-services/_compliance/_create",
    compliance_search :"/bpa-services/_compliance/_search",
    compliance_update :"/bpa-services/_compliance/_update",
    scrutinyDetails: "/edcr/rest/dcr/scrutinydetails",
    comparisionReport: "/edcr/rest/dcr/occomparison",
    create: "/bpa-services/v1/bpa/_create",
    createForm11: "/bpa-services/v1/subdivision/_create",
    createFormCLU: "/bpa-services/v1/subdivision/_create",
    createForm34: "/bpa-services/v1/subdivision/_create",
    createForm26: "/bpa-services/v1/offences/_create",
    nocSearch: "/noc-services/v1/noc/_search",
    updateNOC: "/noc-services/v1/noc/_update",
    update: "/bpa-services/v1/bpa/_update",
    updateForm11: "/bpa-services/v1/subdivision/_update",
    updateFormCLU: "/bpa-services/v1/subdivision/_update",
    updateForm34: "/bpa-services/v1/subdivision/_update",
    bpaSearch: "/bpa-services/v1/bpa/_search",
    searchfrom11: "/bpa-services/v1/subdivision/_search",
    searchfrom26: "/bpa-services/v1/subdivision/_search",
    searchFromCLU: "/bpa-services/v1/subdivision/_search",
    searchFrom34: "/bpa-services/v1/subdivision/_search",
    searchFromComplaint:"/bpa-complaint/v1/_search",
    updateFormComplain: "/bpa-complaint/v1/_update",
    bpaRegSearch: "/tl-services/v1/BPAREG/_search",
    bpaRegCreate: "/tl-services/v1/BPAREG/_create",
    bpaRegGetBill: "/tl-calculator/v1/BPAREG/_getbill",
    bpaRegUpdate: "/tl-services/v1/BPAREG/_update",
    receipt_download: "/egov-pdf/download/PAYMENT/consolidatedreceipt",
    edcrreportdownload: "/bpa-services/v1/bpa/_permitorderedcr",
    designOfApproval:"/bpa-services/_DesignOfApprovalLetter",
    designOfStuctural: "/bpa-services/_Structralcertificate",
    scheduleInspection: "/bpa-services/v1/bpa/_dashboard/_inspection",
    applicationDetailData: "/bpa-services/v1/bpa//_dashboard/_applicationDetailData",
    applicationDetailDataView: "/bpa-services/v1/bpa/_dashboard/_applicationDetailData",
    fetchProfessional: "/tl-services/v1/_dashboard/_applicationDetailData",
    fetchAppendex: "/bpa-services/v1/bpa/_dashboard/_applicationDetailData",
    paymentList: "/collection-services/payments/_plainsearch",
    paymentReport: "/bpa-services/v1/_paymentReport",
    timetaken: "/bpa-services/v1/_averageTimeTaken",
    riskData: "/bpa-services/v1/_riskDataReport",
    echargesReport: "/bpa-services/v1/_echargesReport",
    applicationReport: "/bpa-services/v1/_applicationReport",
    ulbApplicationReport: "/bpa-services/v1/bpa/_dashboard/_ulbwiseCount",
    realtimeApplicationData: "/bpa-services/v1/_formsReport",
    realtimeApplicationUdData: "/bpa-services/v1/_formsUDReport",
    scrutinyFailedReport: "/edcr/rest/dcr/not-accepted-files",
    inwardApplicationReport: "/bpa-services/v1/_inwardApplicationReport",
    selfApprovalReport: "/bpa-services/v1/_selfCertification",
    inspectionSearch: "/inbox/v1/inspection/_search",
    statusData: "/bpa-services/v1/bpa/_dashboard/_tcpHome",
    statusUdData: "/bpa-services/v1/bpa/_dashboard/_udHome",
    createEgscrutiny : "/bpa-services/egscrutiny/_create",
    searchEgscrutiny : "/bpa-services/egscrutiny/_search",
    bpaCalculator: "/bpa-calculator/_feeCalculator"
  },

  edcr: {
    create: "/edcr/rest/dcr/scrutinize",
  },

  events: {
    search: "/egov-user-event/v1/events/_search",
    update: "/egov-user-event/v1/events/lat/_update",
    updateEvent: "/egov-user-event/v1/events/_update",
    updateEventCDG: "/egov-user-event/v1/events/lat/_update",
    count: "/egov-user-event/v1/events/notifications/_count",
    create: "/egov-user-event/v1/events/_create",
  },

  ws: {
    water_search: "/ws-services/wc/_search",
    sewarage_search : "/sw-services/swc/_search",
  },

  engagement: {
    document: {
      search: "/egov-document-uploader/egov-du/document/_search",
      create: "/egov-document-uploader/egov-du/document/_create",
      delete: "/egov-document-uploader/egov-du/document/_delete",
      update: "/egov-document-uploader/egov-du/document/_update",
      newNotification: "/egov-document-uploader/v1/_newNotifications",
      getAllNotification: "/egov-document-uploader/v1/_getAllNotifications",
      notificationFlag: "/egov-document-uploader/v1/_notificationflag",
      deleteNotifications: "/egov-document-uploader/v1/_deleteNotifications",
      submitComplaint: "/egov-document-uploader/api/complaints/submit",
      unauthorizedcomplaint: "/bpa-complaint/v1/_complaint"
    },
    surveys: {
      create: "/egov-survey-services/egov-ss/survey/_create",
      update: "/egov-survey-services/egov-ss/survey/_update",
      search: "/egov-survey-services/egov-ss/survey/_search",
      delete: "/egov-survey-services/egov-ss/survey/_delete",
      submitResponse: "/egov-survey-services/egov-ss/survey/response/_submit",
      showResults: "/egov-survey-services/egov-ss/survey/response/_results"
    }
  },

  noc: {
    nocSearch: "/noc-services/v1/noc/_search"
  },
};

export default Urls;
