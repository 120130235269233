import React from "react";
import { Link } from "react-router-dom";

const CitizenOBPSCard = ({ header, links, state, Icon, Info, isInfo = false, styles }) => {



  return (
    <div className="CitizenOBPSCard" style={{display: "flex", flexDirection:'row', justifyContent: "center", backgroundColor: '#1f4a7c', height:'400px', width:"80%", borderRadius: '10px', margin: 'auto', marginTop: '150px'}}>
    <div>
      <img
        src='https://static.toiimg.com/photo/93246451.cms'
        alt="cititizenobpscard"
        style={{ width: '472px', height: '353px', marginTop: "20px" }}
      />
    </div>
    <div className="CitizenHomeCard" style={styles ? styles : {marginTop: "20px"}}>
      <div className="complaint-links-container">
      <div className="header">
        <h2>{header}</h2>
        <Icon />
      </div>

      <div className="links">
        {links.map((e, i) => (
          <Link key={i} to={{ pathname: e.link, state: e.state }}>
            {e.i18nKey}
          </Link>
        ))}
      </div>
      {/* <div>
        {isInfo ? <Info /> : null} 
      </div> */}
    </div>
    </div>
  </div>
  );
};

export default CitizenOBPSCard;
