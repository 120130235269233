import React from 'react';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const TableComponent = ({ data, handleInputChange }) => {
  return (
    // <TableContainer component={Paper}>
    //   <Table border="1" cellPadding="10" cellSpacing="0">
    //     <TableHead>
    //       <TableRow>
    //         <TableCell>State #</TableCell>
    //         <TableCell>State</TableCell>
    //         <TableCell>Application Status</TableCell>
    //         <TableCell>Action</TableCell>
    //         <TableCell>Next State</TableCell>
    //         <TableCell>Roles</TableCell>
    //       </TableRow>
    //     </TableHead>
    //     <TableBody>
    //       {data?.[0]?.states.map((stateData, index) => (
    //         stateData.actions && stateData.actions.length > 0 ? (
    //           stateData.actions.map((action, actionIndex) => (
    //             <TableRow key={`${index}-${actionIndex}`}>
    //               <TableCell>State #{index + 1}</TableCell>
    //               <TableCell>{stateData.state || "N/A"}</TableCell>
    //               <TableCell>
    //                 <TextField
    //                   variant="outlined"
    //                   size="small"
    //                   value={stateData.applicationStatus || "N/A"}
    //                   onChange={(e) => handleInputChange(e, index)}
    //                 />
    //               </TableCell>
    //               <TableCell>{action.action}</TableCell>
    //               <TableCell>{action.nextState}</TableCell>
    //               <TableCell>{action.roles.join(", ")}</TableCell>
    //             </TableRow>
    //           ))
    //         ) : (
    //           <TableRow key={index}>
    //             <TableCell>State #{index + 1}</TableCell>
    //             <TableCell>{stateData.state || "N/A"}</TableCell>
    //             <TableCell>
    //               <TextField
    //                 variant="outlined"
    //                 size="small"
    //                 value={stateData.applicationStatus || "N/A"}
    //                 onChange={(e) => handleInputChange(e, index)}
    //               />
    //             </TableCell>
    //             <TableCell colSpan="3">No actions available</TableCell>
    //           </TableRow>
    //         )
    //       ))}
    //     </TableBody>
    //   </Table>
    // </TableContainer>
    <TableContainer component={Paper}>
  <Table border="1" cellPadding="10" cellSpacing="0">
    <TableHead>
      <TableRow>
        <TableCell>State #</TableCell>
        <TableCell>State</TableCell>
        <TableCell>Application Status</TableCell>
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {data?.[0]?.states.map((stateData, index) => (
        <TableRow key={index}>
          <TableCell>State #{index + 1}</TableCell>
          <TableCell>{stateData.state || "N/A"}</TableCell>
          <TableCell>
            <TextField
              variant="outlined"
              size="small"
              value={stateData.applicationStatus || "N/A"}
              onChange={(e) => handleInputChange(e, index)}
            />
          </TableCell>
          <TableCell>
            {stateData.actions && stateData.actions.length > 0 ? (
              stateData.actions.map((action, actionIndex) => (
                <div key={actionIndex}>
                  <p><strong>Action:</strong> {action.action}</p>
                  <p><strong>Next State:</strong> {action.nextState}</p>
                  <p><strong>Roles:</strong> {action.roles.join(", ")}</p>
                  <br/>
                  <hr /> {/* Optional to separate each action visually */}
                  <br/>
                </div>
              ))
            ) : (
              "No actions available"
            )}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

  );
};

export default TableComponent;
