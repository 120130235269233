import React, { useState, useEffect, useRef } from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Card, CircularProgress, Box, Snackbar } from '@mui/material';
import axios from 'axios';
import Urls from '@egovernments/digit-ui-libraries/src/services/atoms/urls';

const ViewJobDelegationForm = () => {
  const [employeeList, setEmployeeList] = useState([]);
  const [loading, setLoading] = useState(true); // For showing the loading spinner
  const [openSnackbar, setOpenSnackbar] = useState(false); // For Snackbar notification
  const [snackbarMessage, setSnackbarMessage] = useState("");

  let user = Digit.UserService.getUser();
  const authToken = Digit.UserService.getUser()?.access_token || null;

  // Track if the API has been called already
  const isAPICalled = useRef(false);

  const handleEmployeeResponse = async () => {
    if (isAPICalled.current) return; // Avoid multiple calls
    isAPICalled.current = true; // Mark the API call as in-progress

    const payload = {
      RequestInfo: {
        apiId: "Rainmaker",
        action: "_create",
        did: 1,
        key: "",
        msgId: "20170310130900|en_IN",
        ts: 0,
        ver: ".01",
        authToken: authToken,
        userInfo: user?.info,
      },
    };

    try {
      const response = await axios.post(`${Urls.hrms.EidtJobdelegationSearch}`, payload);
      console.log('API Response:', response?.data); // Log the response for debugging
      setEmployeeList(response?.data?.jobDelegation || []);
      setSnackbarMessage('Data fetched successfully!');
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error fetching data:', error);
      setSnackbarMessage('Failed to fetch employee data.');
      setOpenSnackbar(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Only call the function if authToken and user are valid
    if (authToken && user && !isAPICalled.current) {
      handleEmployeeResponse();
    }
  }, [authToken, user]); // Dependencies ensure the call happens only when necessary

  return (
    <Card>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell rowSpan={2}>Sr.No</TableCell>
                <TableCell rowSpan={2}>User Name</TableCell>
                <TableCell rowSpan={2}>Name</TableCell>
                <TableCell colSpan={2}>Delegation From</TableCell>
                <TableCell colSpan={2}>Delegation TO</TableCell>
                {/* <TableCell rowSpan={2}>Location</TableCell> */}
                <TableCell rowSpan={2}>Date From</TableCell>
                <TableCell rowSpan={2}>Date To</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Office</TableCell>
                <TableCell>Designation</TableCell>
                <TableCell>Office</TableCell>
                <TableCell>Designation</TableCell>
              </TableRow>

            </TableHead>
            <TableBody>
              {employeeList.map((employee, index) => (
                <TableRow key={employee.id || index}>
                  <TableCell>{index + 1}</TableCell> {/* Display index */}
                  <TableCell>{employee.userName || "N/A"}</TableCell>  {/* Display employee userName */}
                  <TableCell>{employee.name || "N/A"}</TableCell>
                 
                  {/* Display Office and Name in two cells under DesignationTO */}
                  <TableCell>{employee.currentLocation || "N/A"}</TableCell>
                  <TableCell>{employee.oldDesignation || "N/A"}</TableCell>
                  <TableCell>{employee.location1 || "N/A"}/{employee.location2 || "N/A"}</TableCell>
                  <TableCell>{employee.currentDesignation}</TableCell>
                  <TableCell>{employee.startDate || "N/A"}</TableCell>  {/* Display start date */}
                  <TableCell>{employee.endDate || "N/A"}</TableCell>  {/* Display end date */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {/* Snackbar for notifications */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
        message={snackbarMessage}
      />
    </Card>
  );
};

export default ViewJobDelegationForm;
