import React, { useEffect, useState } from 'react';
import InputRenderer from './InputRenderer';

const FullAddress = ({ t, config, onSelect, formData, errors }) => {
	const inputs = [
		{
			label: "CORE_COMMON_ADDRESS",
			type: "text",
			name: "address",
			validation: {
				isRequired: false,
				// pattern: Digit.Utils.getPattern('Address'),
				// title: t("CORE_COMMON_APPLICANT_ADDRESS_INVALID"),
			}
		}, {
			label: "CORE_COMMON_STATE",
			type: "text",
			name: "state",
			validation: {
				isRequired: false,
				// pattern: Digit.Utils.getPattern('Address'),
				// title: t("CORE_COMMON_APPLICANT_ADDRESS_INVALID"),
			}
		}, {
			label: "CORE_COMMON_DISTRICT",
			type: "text",
			name: "district",
			validation: {
				isRequired: false,
				// pattern: Digit.Utils.getPattern('Address'),
				// title: t("CORE_COMMON_APPLICANT_ADDRESS_INVALID"),
			}
		}, {
			label: "CORE_COMMON_TEHSIL",
			type: "text",
			name: "tehsil",
			validation: {
				isRequired: false,
				// pattern: Digit.Utils.getPattern('Address'),
				// title: t("CORE_COMMON_APPLICANT_ADDRESS_INVALID"),
			}
		}, {
			label: "CORE_COMMON_TOWN",
			type: "text",
			name: "town",
			validation: {
				isRequired: false,
				// pattern: Digit.Utils.getPattern('Address'),
				// title: t("CORE_COMMON_APPLICANT_ADDRESS_INVALID"),
			}
		}, {
			label: "CORE_COMMON_PINNO",
			type: "number",
			name: "pinno",
			validation: {
				isRequired: false,
				// pattern: Digit.Utils.getPattern('Address'),
				// title: t("CORE_COMMON_APPLICANT_ADDRESS_INVALID"),
			}
		},
	];
	const initData = {
		...(inputs.reduce((obj, key) => ({ ...obj, [key.name]: '' }), {})),
	};
	const [addressDetails, setAddressDetails] = useState(formData[config.key] || initData);
	const [isPermanentSameAsCorrespondence, setIsPermanentSameAsCorrespondence] = useState(false);

	const updateAddressDetails = (key, value) => {
		const address = { ...addressDetails }
		address[key] = value;
		setAddressDetails(address);
	};

	const togglePermanentSameAsCorrespondence = () => {
		setIsPermanentSameAsCorrespondence(!isPermanentSameAsCorrespondence);
		setAddressDetails(isPermanentSameAsCorrespondence ? initData : formData['correspondenceAddress']);
	};

	useEffect(() => {
		if (isPermanentSameAsCorrespondence) {
			setAddressDetails(formData['correspondenceAddress']);
		}
	}, [formData['correspondenceAddress']]);

	useEffect(() => {
		if (addressDetails) {
			onSelect(config.key, addressDetails);
		}
	}, [addressDetails]);

	return (
		<div>
			<h3 style={{
				fontSize: 20,
				fontWeight: 'bold',
				marginBottom: 30,
				...(config.key === 'permanentAddress' && { marginTop: 20 })
			}}>{config.title}</h3>
			{config.key === 'permanentAddress' && (
				<label style={{ display: 'flex', gap: 20, marginBottom: 30 }}>
					<input
						type='checkbox'
						checked={isPermanentSameAsCorrespondence}
						onChange={togglePermanentSameAsCorrespondence}
						style={{ height: 24, width: 24 }}
					/>
					<span>Is Same as Correspondence Address?</span>
				</label>
			)}
			<InputRenderer {...{ t, config, formData, inputs, errors }} updateInput={updateAddressDetails} />
		</div>
	)
}

export default FullAddress;
