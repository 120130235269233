import React from 'react';
import {
	CardLabel,
	LabelFieldPair,
	CardLabelError,
	TextInput,
} from "@egovernments/digit-ui-react-components";

const InputRenderer = ({ t, config, formData = {}, inputs, errors, isDisabled = false, value, defaultValue, updateInput }) => (
	inputs?.map(input => {
		const currentValue = formData && formData[config?.key] && formData[config?.key][input.name] || '';

		return (
			<div key={input.name}>
				{errors?.[input.name] && <CardLabelError>{t(input.error)}</CardLabelError>}
				<LabelFieldPair>
					<CardLabel className="card-label-smaller">
						{t(input.label)} {input.validation.isRequired ? "*" : null}
					</CardLabel>

					<div className="field">
						<TextInput
							disable={input.disabled || isDisabled}
							disabled={input.disabled || isDisabled}
							key={input.name}
							type={input.type}
							autoComplete={input.autoComplete || 'new-password'}
							value={value || formData?.[config?.key]?.[input.name]}
							defaultValue={defaultValue || formData?.[config?.key]?.[input.name]}
							onChange={(e) => updateInput?.(input.name, e.target.value)}
							{...input.validation}
						/>

						{currentValue && currentValue.length > 0 && !currentValue.match(input.validation.pattern) && (
							<CardLabelError style={{ width: "100%", marginTop: '-15px', fontSize: '16px', marginBottom: '12px' }}>
								{t(input.validation.title || 'This field is invalid')}
								{input.type === 'password' && (
									<div style={{ fontSize: '0.9rem' }}>
										<strong>A Valid Password should contain:</strong><br />
										<ul style={{ paddingLeft: '1rem', listStyle: 'disc' }}>
											<li>Minimum eight characters</li>
											{/* <li>At least one uppercase letter</li>
											<li>one lowercase letter</li> */}
											<li>one number</li>
											<li>one letter</li>
											{/* <li>one special character</li> */}
										</ul>
									</div>
								)}
							</CardLabelError>
						)}
					</div>
				</LabelFieldPair>
			</div>
		)
	})
);

export default InputRenderer;
