import { CardLabel, Dropdown, LabelFieldPair, Loader, RemoveableTag, MultiSelectDropdown, RadioButtons } from "@egovernments/digit-ui-react-components";
import React, { useEffect, useState } from "react";
import cleanup from "../Utils/cleanup";
import InputRenderer from "./InputRenderer";
// import MultiSelectDropdown from "./Multiselect";

const Jurisdictions = ({ t, config, onSelect, userType, errors, formData }) => {
  const tenantId = Digit.ULBService.getStateId();
  /*
  const [inactiveJurisdictions, setInactiveJurisdictions] = useState([]);
  */
  const { data: data = {}, isLoading } = Digit.Hooks.hrms.useHrmsMDMS(tenantId, "egov-hrms", "HRMSRolesandDesignation") || {};
  const [jurisdictions, setjurisdictions] = useState(
    formData?.Jurisdictions || [
      {
        id: undefined,
        key: 1,
        department: null,
        city: null,
        locality: null,
        areaType: null,
        village: [],
        hierarchy: null,
        boundaryType: null,
        boundary: null,
        roles: [],
      },
    ]
  );

  useEffect(() => {
    const jurisdictionsData = jurisdictions?.map((jurisdiction) => {
      let res = {
        id: jurisdiction?.id,
        hierarchy: "REVENUE",
        boundaryType: "Locality",
        boundary: "hp.tcpsolan",
        // hardcoded hierarchy, boundaryType and boundary as discussed with Renuka
        department: jurisdiction?.department,
        city: jurisdiction?.city?.name,
        locality: jurisdiction?.locality?.name,
        areaType: jurisdiction?.areaType,
        village: jurisdiction?.village,
        tenantId: jurisdiction?.city?.code,
        /*
        tenantId: jurisdiction?.boundary?.code,
        auditDetails: jurisdiction?.auditDetails,
        */
      };
      //res = cleanup(res);
      if (jurisdiction?.roles) {
        res["roles"] = jurisdiction?.roles.map((ele) => {
          delete ele.description;
          return ele;
        });
      }
      if(jurisdiction?.village){
        res["village"] = jurisdiction?.village?.map(vill => ({
          ward: vill.name
      }));
      }
      return res;
    });

    onSelect(
      config.key,
      /*
      [...jurisdictionsData, ...inactiveJurisdictions].filter((value) => Object.keys(value).length !== 0)
      */
      [...jurisdictionsData].filter((value) => Object.keys(value).length !== 0)
    );
  }, [jurisdictions]);

  /*
  const reviseIndexKeys = () => {
    setjurisdictions((prev) => prev.map((unit, index) => ({ ...unit, key: index })));
  };

  const handleAddUnit = () => {
    setjurisdictions((prev) => [
      ...prev,
      {
        key: prev.length + 1,
        hierarchy: null,
        boundaryType: null,
        boundary: null,
        roles: [],
      },
    ]);
  };
  const handleRemoveUnit = (unit) => {
    if (unit.id) {
      let res = {
        id: unit?.id,
        hierarchy: unit?.hierarchy?.code,
        boundaryType: unit?.boundaryType?.label,
        boundary: unit?.boundary?.code,
        tenantId: unit?.boundary?.code,
        auditDetails: unit?.auditDetails,
        isdeleted: true,
        isActive: false,
      };
      res = cleanup(res);
      if (unit?.roles) {
        res["roles"] = unit?.roles.map((ele) => {
          delete ele.description;
          return ele;
        });
      }
      setInactiveJurisdictions([...inactiveJurisdictions, res]);
    }
    setjurisdictions((prev) => prev.filter((el) => el.key !== unit.key));
    if (FormData.errors?.Jurisdictions?.type == unit.key) {
      clearErrors("Jurisdictions");
    }
    reviseIndexKeys();
  };
  let hierarchylist = [];
  let boundaryTypeoption = [];
  */
  const [focusIndex, setFocusIndex] = useState(-1);
  /*
    function gethierarchylistdata() {
      return data?.MdmsRes?.["egov-location"]["TenantBoundary"].map((ele) => ele.hierarchyType);
    }
  
    function getboundarydata() {
      return [];
    }
  */
  function getroledata() {
    return data?.MdmsRes?.["ACCESSCONTROL-ROLES"].roles.map(role => { return { code: role.code, name: role?.name ? role?.name : " ", labelKey: 'ACCESSCONTROL_ROLES_ROLES_' + role.code } });
  }

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      {jurisdictions?.map((jurisdiction, index) => (
        <Jurisdiction
          t={t}
          config={config}
          errors={errors}
          formData={formData}
          jurisdictions={jurisdictions}
          key={index}
          keys={jurisdiction.key}
          data={data}
          jurisdiction={jurisdiction}
          setjurisdictions={setjurisdictions}
          index={index}
          focusIndex={focusIndex}
          setFocusIndex={setFocusIndex}
          // gethierarchylistdata={gethierarchylistdata}
          // hierarchylist={hierarchylist}
          // boundaryTypeoption={boundaryTypeoption}
          // getboundarydata={getboundarydata}
          getroledata={getroledata}
        // handleRemoveUnit={handleRemoveUnit}
        />
      ))}
      {/*
      <label onClick={handleAddUnit} className="link-label" style={{ width: "12rem" }}>
        {t("HR_ADD_JURISDICTION")}
      </label>
      */}
    </div>
  );
};
function Jurisdiction({
  t,
  data,
  config,
  errors,
  formData,
  jurisdiction,
  jurisdictions,
  setjurisdictions,
  // gethierarchylistdata,
  // handleRemoveUnit,
  // hierarchylist,
  getroledata,
  roleoption,
  index,
}) {
  // const [BoundaryType, selectBoundaryType] = useState([]);
  // const [Boundary, selectboundary] = useState([]);
  const [localities, setLocalities] = useState(jurisdiction?.city?.boundary || null);
  const localityIndex = localities?.findIndex((e) => e.name === jurisdiction?.locality?.name);
  const [villageList, setVillageList] = useState(localities?.[localityIndex]?.children || []);
  /*
    useEffect(() => {
      selectBoundaryType(
        data?.MdmsRes?.["egov-location"]["TenantBoundary"]
          .filter((ele) => {
            return ele?.hierarchyType?.code == jurisdiction?.hierarchy?.code;
          })
          .map((item) => { return { ...item.boundary, i18text: Digit.Utils.locale.convertToLocale(item.boundary.label, 'EGOV_LOCATION_BOUNDARYTYPE') } })
      );
    }, [jurisdiction?.hierarchy, data?.MdmsRes]);
  */
  const { data: cities, isLoading: citiesLoading } = Digit.Hooks.useTenants();
  const citymoduleList = !citiesLoading && jurisdiction?.department?.code ? cities?.filter((city) => city.code?.includes(jurisdiction.department.code)) : [];
  /*
    useEffect(() => {
      selectboundary(data?.MdmsRes?.tenant?.tenants.filter(city => city.code != Digit.ULBService.getStateId() && (department && city.code.includes(department.code))).map(city => { return { ...city, i18text: Digit.Utils.locale.getCityLocale(city.code) } }));
    }, [jurisdiction?.boundaryType, data?.MdmsRes]);
  
    useEffect(() => {
      if (Boundary?.length > 0) {
        selectedboundary(Boundary?.filter((ele) => ele.code == jurisdiction?.boundary?.code)[0]);
      }
    }, [Boundary]);
  */
  const selectDepartment = (value) => {
    selectCity(null);
    setLocalities(null);
    setVillageList([]);
    // selectHierarchy(null);
    // selectboundaryType(null);
    // selectedboundary(null);
    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, department: value, } : item)));
  };
  const selectCity = (value) => {
    selectedLocality(null);
    setLocalities(value?.boundary);
    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, city: value } : item)));
  };
  const selectedLocality = (value) => {
    selectedVillage(null);
    let areaType = '';
    if (value !== null) {
      areaType = value?.IsSpecialArea ? `Special Area` : `Planning Area`;
      setVillageList(value?.children);
    }
    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, locality: value, areaType } : item)));
  };
  const selectedVillage = (value) => {
    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, village: value } : item)));
  };

  /*
  const selectHierarchy = (value) => {
    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, hierarchy: value } : item)));
  };

  const selectboundaryType = (value) => {
    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, boundaryType: value } : item)));
  };

  const selectedboundary = (value) => {
    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, boundary: value } : item)));
  };
*/
  const selectrole = (e, data) => {
    // const index = jurisdiction?.roles.filter((ele) => ele.code == data.code);
    // let res = null;
    // if (index.length) {
    //   jurisdiction?.roles.splice(jurisdiction?.roles.indexOf(index[0]), 1);
    //   res = jurisdiction.roles;
    // } else {
    //   res = [{ ...data }, ...jurisdiction?.roles];
    // }
    let res = [];
    e && e?.map((ob) => {
      res.push(ob?.[1]);
    });
    res?.forEach(resData => { resData.labelKey = 'ACCESSCONTROL_ROLES_ROLES_' + resData.code })
    const employeeRole = {
      "code": "EMPLOYEE",
      "name": "Employee",
      "labelKey": "ACCESSCONTROL_ROLES_ROLES_EMPLOYEE"
    }
    const hasEmployeeRole = res?.find(role => role.code === 'EMPLOYEE')
    if (!hasEmployeeRole) {
      res.push(employeeRole);
    }
    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, roles: res } : item)));
  };
  const selectWard = (e, data) => {
    // const index = jurisdiction?.roles.filter((ele) => ele.code == data.code);
    // let res = null;
    // if (index.length) {
    //   jurisdiction?.roles.splice(jurisdiction?.roles.indexOf(index[0]), 1);
    //   res = jurisdiction.roles;
    // } else {
    //   res = [{ ...data }, ...jurisdiction?.roles];
    // }
    let res = [];
    e && e?.map((ob) => {
      res.push(ob?.[1]);
    });
    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, village: res } : item)));
  };


  const onRemove = (data) => {
    if (jurisdiction?.roles?.length <= 1 || data.code === 'EMPLOYEE') {
      return;
    }
    let afterRemove = jurisdiction?.roles.filter((value) => {
      if (value.code !== 'EMPLOYEE' || data.code !== 'EMPLOYEE') {
        return value.code !== data.code;
      }
    });

    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, roles: afterRemove } : item)));
  };
  const onRemoveVillage = (data) => {
    if (jurisdiction?.village?.length <= 1 ) {
      return;
    }
    let afterRemove = jurisdiction?.village?.filter((value) => {
      return value.name !== data.name
    });
    setjurisdictions((pre) => pre.map((item) => (item.key === jurisdiction.key ? { ...item, village: afterRemove } : item)));
  };
  return (
    <div key={jurisdiction?.keys} style={{ marginBottom: "16px" }}>
      <div style={{ border: "1px solid #E3E3E3", padding: "16px", marginTop: "8px" }}>
        {/*
        <LabelFieldPair>
          <div className="label-field-pair" style={{ width: "100%" }}>
            <h2 className="card-label card-label-smaller" style={{ color: "#505A5F" }}>
              {t("HR_JURISDICTION")} {index + 1}
            </h2>
          </div>
          {jurisdictions.length > 1 ? (
            <div
              onClick={() => handleRemoveUnit(jurisdiction)}
              style={{ marginBottom: "16px", padding: "5px", cursor: "pointer", textAlign: "right" }}
            >
              X
            </div>
          ) : null}
        </LabelFieldPair>
        */}
        <LabelFieldPair>
          <CardLabel isMandatory={true} className="card-label-smaller">{`${t("HR_DEPARTMENT_LABEL")} * `}</CardLabel>
          <div className="field">
            <RadioButtons
              style={{ display: "flex", gap: "3rem" }}
              labelStyles={{
                flexDirection: 'row',
                marginLeft: 0,
                gap: '1rem',
              }}
              options={[
                {
                  code: "tcp",
                  name: "TCP",
                },
                {
                  code: "ud",
                  name: "UD",
                },
              ]}
              key='department'
              optionsKey='name'
              selectedOption={jurisdiction?.department || null}
              onSelect={(e) => {
                selectDepartment(e);
              }}
              disable={false}
              defaultValue={undefined}
              t={t}
            />
          </div>
        </LabelFieldPair>

        <LabelFieldPair>
          <CardLabel isMandatory={true} className="card-label-smaller">{`${t("BPA_DISTRICT_LABEL")} * `}</CardLabel>
          <Dropdown
            className="form-field"
            selected={jurisdiction?.city}
            disable={citymoduleList?.length === 0}
            isMandatory={true}
            option={citymoduleList || []}
            select={selectCity}
            optionKey="name"
            name="city"
            t={t}
          />
        </LabelFieldPair>

        {jurisdiction?.department && jurisdiction?.city && (
          <React.Fragment>
            <LabelFieldPair>
              <CardLabel isMandatory={true} className="card-label-smaller">
                {`${t(jurisdiction?.department?.code === 'tcp' ? "BPA_LOC_NOTIFIED-AREA_LABEL" : "BPA_ULB-AREA_LABEL")} * `}
              </CardLabel>
              <Dropdown
                className="form-field"
                selected={jurisdiction?.locality}
                disable={citymoduleList?.length <= 0 && jurisdiction?.city?.length <= 0}
                isMandatory={true}
                option={localities || []}
                select={selectedLocality}
                optionKey="name"
                name="boundary"
                t={t}
              />
            </LabelFieldPair>

            <InputRenderer
              {...{
                t, config, formData, inputs: [
                  {
                    label: "BPA_AREA_TYPE_LABEL",
                    type: "text",
                    name: "areaType",
                    validation: {
                      isRequired: false,
                    }
                  }
                ], errors
              }}
              isDisabled
              value={jurisdiction?.areaType}
              updateInput={(name, value) => {
                console.info(t("BPA_AREA_TYPE_LABEL"), name, value)
              }}
            />

            {villageList?.length > 0 && (
              <LabelFieldPair>
                <CardLabel isMandatory={true} className="card-label-smaller">
                  {`${t(jurisdiction?.department?.code === 'tcp' ? "BPA_LOC_VILLAGE-AREA_LABEL" : "BPA_LOC_WARD-AREA_LABEL")} * `}
                </CardLabel>
                <div className="form-field">
                {/* <Dropdown
                  className="form-field"
                  selected={jurisdiction?.village}
                  disable={citymoduleList?.length <= 0 && jurisdiction?.city?.length <= 0 && localities?.length <= 0 && villageList?.length <= 0}
                  isMandatory={true}
                  option={villageList || []}
                  select={selectedVillage}
                  optionKey="name"
                  name="boundary"
                  t={t}
                /> */}
                <MultiSelectDropdown
                  className="form-field"
                  defaultUnit="Selected"
                  selected={[]}
                  options={villageList || []}
                  onSelect={selectWard}
                  optionsKey="name"
                  t={t}
                  disable={citymoduleList?.length <= 0 && jurisdiction?.city?.length <= 0 && localities?.length <= 0 && villageList?.length <= 0}
                />
                <div className="tag-container">
                  {jurisdiction?.village?.length > 0 &&
                    jurisdiction?.village?.map((value, index) => {
                      return <RemoveableTag key={index} text={`${t(value["name"]).slice(0, 22)} ...`} onClick={() => onRemoveVillage(value)} />;
                    })}
                </div>
                </div>
              </LabelFieldPair>
            )}
          </React.Fragment>
        )}

        {/*
        <LabelFieldPair>
          <CardLabel isMandatory={true} className="card-label-smaller">{`${t("HR_HIERARCHY_LABEL")} * `}</CardLabel>
          <Dropdown
            className="form-field"
            selected={jurisdiction?.hierarchy}
            disable={!jurisdiction?.city}
            isMandatory={true}
            option={gethierarchylistdata(hierarchylist) || []}
            select={selectHierarchy}
            optionKey="code"
            t={t}
          />
        </LabelFieldPair>
        <LabelFieldPair>
          <CardLabel className="card-label-smaller">{`${t("HR_BOUNDARY_TYPE_LABEL")} * `}</CardLabel>
          <Dropdown
            className="form-field"
            isMandatory={true}
            selected={jurisdiction?.boundaryType}
            disable={BoundaryType?.length === 0}
            option={BoundaryType}
            select={selectboundaryType}
            optionKey="i18text"
            t={t}
          />
        </LabelFieldPair>
        <LabelFieldPair>
          <CardLabel className="card-label-smaller">{`${t("HR_BOUNDARY_LABEL")} * `}</CardLabel>
          <Dropdown
            className="form-field"
            isMandatory={true}
            selected={jurisdiction?.boundary}
            disable={Boundary?.length === 0}
            option={Boundary}
            select={selectedboundary}
            optionKey="i18text"
            t={t}
          />
        </LabelFieldPair>
      */}

        <LabelFieldPair>
          <CardLabel className="card-label-smaller">{t("HR_COMMON_TABLE_COL_ROLE")} *</CardLabel>
          <div className="form-field">
            <MultiSelectDropdown
              className="form-field"
              isMandatory={true}
              defaultUnit="Selected"
              selected={jurisdiction?.roles}
              options={getroledata(roleoption)}
              onSelect={selectrole}
              optionsKey="labelKey"
              t={t}
            />
            <div className="tag-container">
              {jurisdiction?.roles.length > 0 &&
                jurisdiction?.roles.map((value, index) => {
                  return <RemoveableTag key={index} text={`${t(value["labelKey"]).slice(0, 22)} ...`} onClick={() => onRemove(value)} />;
                })}
            </div>
          </div>
        </LabelFieldPair>
      </div>
    </div>
  );
}

export default Jurisdictions;
