import React from 'react'
import InputRenderer from './InputRenderer'

export default function PasswordField({t, config, onSelect, formData, errors}) {
	const inputs = [
		{
			label: "HR_EMP_PASSWORD_LABEL",
			type: "password",
			name: "password",
			validation: {
				isRequired: true,
				// pattern: Digit.Utils.getPattern('Password'),
				// title: t("CORE_COMMON_APPLICANT_PASSWORD_INVALID"),
			}
		}
	]
	const updateField = (key, value) => {
    onSelect(config.key, value);
	};

	return (
		<InputRenderer {...{ t, config, formData, inputs, errors }} updateInput={updateField} />
	)
}
