import axios from "axios";

export const getDocumentByFileStoreId = async (fileStoreId, setLoader, needURL = false) => {
  setLoader(true);
  try {
    const response = await axios.get(`/filestore/v1/files/url?tenantId=hp&fileStoreIds=${fileStoreId}`, {});
    const FILEDATA = response.data.fileStoreIds[0].url || ''; 
    if (needURL) {
      return FILEDATA;
    }
    window.open(FILEDATA);
  } catch (error) {
    return error;
  }
  finally {
    setLoader(false);
  }
};

const getFileTypeFromFileStoreURL = (filestoreURL) => {
    if(filestoreURL.includes(".pdf")) return "pdf"
    if(filestoreURL.includes(".jpg") || filestoreURL.includes(".jpeg") || filestoreURL.includes(".png") || filestoreURL.includes(".webp")) return "image"
    else return "image"
}

export default getFileTypeFromFileStoreURL