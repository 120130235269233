// import React from "react";
// import TableComponent from "./indexTableComponent";





// const InboxWorkFlowComponent = () => {
//     const tenantId = Digit.ULBService.getCurrentTenantId();
//     const businessServices = "BPA_UDARKI"
//     const fetch = async () =>
//         await Digit.WorkflowService.init(tenantId, businessServices.join()).then((res) => {
//           const { BusinessServices: data } = res;
//           console.log(res,"dataWorkFlow333333333");
//           return data;
//         });
//     // let workflowDetails = Digit.Hooks.useWorkflowDetails({
//     //     tenantId: tenantId,
//     //     id: "HPUD12202430783",
//     //     moduleCode: businessService,
//     //   });

 

//   return (
//  <React.Fragment>
//     <TableComponent/>

//  </React.Fragment>
//   );
// };

// export default InboxWorkFlowComponent;






import React, { useEffect, useState } from "react";
import TableComponent from "./indexTableComponent";

const InboxWorkFlowComponent = () => {
  const tenantId = Digit.ULBService.getCurrentTenantId();
  const businessServices = "BPA_UDARKI";
  const [workflowData, setWorkflowData] = useState(null);
  const [error, setError] = useState(null);

  

  useEffect(() => {
    const fetchWorkflow = async () => {
      try {
        const res = await Digit.WorkflowService.init(tenantId, businessServices);
        const { BusinessServices: data } = res;
        console.log(res,data , "dataWorkFlow333333333");
        setWorkflowData(data); // Set workflow data to state
      } catch (error) {
        console.error("Error fetching workflow data:", error);
        setError(error); // Set error in case API fails
      }
    };

    fetchWorkflow();
  }, [tenantId, businessServices]);

   // Handle the input change for the application status
   const handleInputChange = (e, stateIndex) => {
    const newValue = e.target.value;
    
    setTableData(prevData => {
      const newData = [...prevData];
      newData[0].states[stateIndex].applicationStatus = newValue;
      return newData;
    });
  };

  return (
    <React.Fragment>
      {error && <div>Error fetching workflow data: {error.message}</div>}
      {workflowData ? <TableComponent data={workflowData} handleInputChange={handleInputChange} /> : <div>Loading...</div>}
    </React.Fragment>
  );
};

export default InboxWorkFlowComponent;
