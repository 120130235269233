export const LOCALIZATION_KEY = {
  CS_COMPLAINT_DETAILS: "CS_COMPLAINT_DETAILS",
  CS_COMMON: "CS_COMMON",
  CS_COMPLAINT: "CS_COMPLAINT",
  CS_FEEDBACK: "CS_FEEDBACK",
  CS_HEADER: "CS_HEADER",
  CS_HOME: "CS_HOME",
  CS_ADDCOMPLAINT: "CS_ADDCOMPLAINT",
  CS_REOPEN: "CS_REOPEN",
  CS_CREATECOMPLAINT: "CS_CREATECOMPLAINT",
  PT_COMMONS: "PT_COMMONS",
  CORE_COMMON: "CORE_COMMON",
};

export const LOCALE = {
  MY_COMPLAINTS: "CS_HOME_MY_COMPLAINTS",
  NO_COMPLAINTS: "CS_MYCOMPLAINTS_NO_COMPLAINTS",
  NO_COMPLAINTS_EMPLOYEE: "CS_MYCOMPLAINTS_NO_COMPLAINTS_EMPLOYEE",
  ERROR_LOADING_RESULTS: "CS_COMMON_ERROR_LOADING_RESULTS",
};
