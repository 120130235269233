import React, { useEffect, useState } from "react";
import { Card, Banner, CardText, SubmitBar, Loader, LinkButton, ActionBar } from "@egovernments/digit-ui-react-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HrmsService from '../../../../libraries/src/services/elements/HRMS'

const GetMessage = (type, action, isSuccess, isEmployee, t) => {
  return t(`EMPLOYEE_RESPONSE_${action ? action : "CREATE"}_${type}${isSuccess ? "" : "_ERROR"}`);
};

const GetActionMessage = (action, isSuccess, isEmployee, t) => {
  return GetMessage("ACTION", action, isSuccess, isEmployee, t);
};

const GetLabel = (action, isSuccess, isEmployee, t) => {
  if (isSuccess) {
    return t("HR_EMPLOYEE_ID_LABEL");
  }
};

const BannerPicker = (props) => {
  return (
    <Banner
      message={GetActionMessage(props.action, props.isSuccess, props.isEmployee, props.t)}
      applicationNumber={props.isSuccess ? props?.data?.code : ''}
      info={GetLabel(props.action, props.isSuccess, props.isEmployee, props.t)}
      successful={props.isSuccess}
    />
  );
};

const ResponseFinal = (props) => {
  const { t } = useTranslation();
  const tenantId = Digit.ULBService.getCurrentTenantId();
  const { state } = props.location;
  const [loading, setLoading] = useState(false);
  const [successData, setSuccessData] = useState(null);
  const [errorInfo, setErrorInfo] = useState(null);
  const [mutationHappened, setMutationHappened] = useState(false);

  const createEmployee = async (data) => {
    setLoading(true);
    try {
      const result = await HrmsService.create(data, tenantId);
      setSuccessData(result.Employees[0]);
      setMutationHappened(true);
    } catch (error) {
      setErrorInfo(error.message || 'ERROR');
      setMutationHappened(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!mutationHappened) {
      if (state.key === "UPDATE") {
        createEmployee({ Employees: state.Employees });
      } else {
        createEmployee(state);
      }
    }
  }, [state, mutationHappened]);

  const DisplayText = (action, isSuccess, isEmployee, t) => {
    if (!isSuccess) {
      return errorInfo || mutation?.error?.response?.data?.Errors[0].code;
    } else {
      Digit.SessionStorage.set("isupdate", Math.floor(100000 + Math.random() * 900000));
      return state.key === "CREATE" ? "HRMS_CREATE_EMPLOYEE_INFO" : "";
    }
  };

  if (loading || (!mutationHappened && !successData)) {
    return <Loader />;
  }

  return (
    <Card>
      <BannerPicker
        t={t}
        data={successData}
        action={state.action}
        isSuccess={!!successData}
        isLoading={loading}
        isEmployee={props.parentRoute.includes("employee")}
      />
      <CardText>{t(DisplayText(state.action, !!successData, props.parentRoute.includes("employee"), t), t)}</CardText>

      <ActionBar>
        <Link to={`${props.parentRoute.includes("employee") ? "/digit-ui/employee" : "/digit-ui/citizen"}`}>
          <SubmitBar label={t("CORE_COMMON_GO_TO_HOME")} />
        </Link>
      </ActionBar>
    </Card>
  );
};

export default ResponseFinal;
